<script setup lang="ts">
import { onMounted } from 'vue'

defineLayout({
  label: 'Kimpton Layout',
  colors: {
    fields: {
      picker: true,
      palette: true,
    },
    background: {
      picker: true,
      palette: true,
    },
    foreground: {
      picker: true,
      palette: true,
    },
  },

  colorPalette: {
    '#20432C': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#7A8F70': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#DF6548': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#FA9CA2': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#E7AD16': {
      fields: true,
      background: true,
      foreground: true,
    },
  },
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()
const currentResortInStaging = true

const links: Array<object> = [
  { rel: 'icon', href: '/imgs/kimpton/favicons/icon-192.png', type: 'image/png' },
  { rel: 'icon', href: '/imgs/kimpton/favicons/icon.svg', type: 'image/svg+xml' },
  { rel: 'apple-touch-icon', href: '/imgs/kimpton/favicons/apple-touch-icon.png', type: 'image/png' },
  { rel: 'manifest', href: '/imgs/kimpton/favicons/manifest.json', type: 'application/json' },
]

if (!isVoixTemplatizer)
  setupHeader(null, links)

const {
  currentResort,
} = await getCommonLayoutData(isVoixTemplatizer, ['resorts', 'brands', 'footerCompanyMenu', 'footerResourcesMenu', 'footerTermsMenu'], currentResortInStaging)

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

if (!isVoixTemplatizer)
  setupHeader('0191c79b-00d6-7e7d-8aad-780f97c8f6ba')

provide('accentColor', { value: '#DF6548' })
provide('currentResort', currentResort.value.data)
provide('currentResortInStaging', currentResortInStaging)
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white kimpton-theme"
    >
      <div id="app" class="bg-bonita-inverse">
        <div id="portal-destination" transition="fade-transition" />
        <div id="portal-mobile-menu" role="navigation" />
        <div id="kimpton-content">
          <KimptonNavigation
            v-if="!isVoixTemplatizer"
            :current-resort="currentResort?.data"
            class="font-sans"
            role="landmark"
          />

          <div class="text-gray-500">
            <slot />
          </div>

          <KimptonFooter
            v-if="!isVoixTemplatizer"
            :current-resort="currentResort?.data"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/css/kimpton.scss';
</style>
